<template>
    <v-container fluid class="background">
      <v-layout align-center justify-center>
        <div class="d-flex justify-center">
          <v-card class="login-card">
            <v-card-text>
              <v-form @submit.prevent="login">
                <v-text-field v-model="userFields.username" label="Username" outlined solo></v-text-field>
                <v-text-field v-model="userFields.password" label="Password" type="password" outlined solo></v-text-field>
                <v-btn color="primary" class="white--text" block type="submit">Login</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </template>
  
  
<script>
import axios from 'axios';
import router from '@/router';

export default {
    name: 'SignIn',
    components: {
    },
    data() {
        return {
            APIEndPoint: '',
            userFields: {
                username: '',
                password: '',
            },
            errors: {
                login: false,
                login_message: '',
            },
            actions: {
                loginRunning: false,
                login: false,
            }
        }
    },
    methods: {
        login() {
            var postFields = {
                userData: this.userFields,
                action: 'login',
            }
            axios.post(this.APIEndPoint + 'users.php', postFields, { withCredentials: true })
                .then(response => {
                    if (response.data.status === false) {
                        console.log('false');
                    } else {
                        setTimeout(() => {
                            console.log('true');
                            router.push('/');
                            this.userFields.username = ''
                            this.userFields.password = ''
                            this.errors.login = false
                            this.errors.login_message = false
                            this.actions.loginRunning = false
                            this.actions.login = false
                        }, 1500)
                    }

                })

        },

        isAuth() {
            var postFields = {
                action: 'isAuth',
            }
            axios.post(this.APIEndPoint + 'users.php', postFields, { withCredentials: true })
                .then(response => {
                    if (response.data.status === false) {
                        console.log('Not loggedin')
                    } else {
                        router.push('/');
                    }
                })
        },
    },
    created() {
        this.APIEndPoint = this.$hostname;
    },
    mounted() {
        this.isAuth();
    }
}
</script>

<style scoped>
  .background {
    background-color: #f7f7f7;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-card {
    max-width: 500px;
    padding: 20px;
    text-align: center;
  }
</style>