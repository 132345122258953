<template>
  <nav class="top-bar">
      <v-app-bar app fixed color="white">
          <img src="../assets/logo2.png" width="100px">
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleDrawer" class="ml-2">
          <v-icon>mdi-menu</v-icon>
          </v-btn>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" app right>
          <v-list>
              <v-list-item>
                  <v-list-item-icon>
                      <v-icon>mdi-view-dashboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>Dashboard</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openAdduser">
                  <v-list-item-icon>
                      <v-icon>mdi-account-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>Add user</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item  @click="logout()">
                  <v-list-item-icon>
                      <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>Logout</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
          </v-list>
      </v-navigation-drawer>
  </nav>
</template>


  
  <script>
  import axios from 'axios';
  import router from '@/router';
  export default {
    props:{
    },
    name: 'loginHeader',
    data() {
      return {
        drawer: false,
      };
    },
    methods: {
      openAdduser() {
      this.$emit('open-add-user');
    },
      openProfile() {
        this.$emit('open-user-profile');
      },
      openConversations() {
        this.$emit('open-conversations');
      },
      toggleDrawer() {
          this.drawer = !this.drawer;
      },
      logout() {
        var postFields = {
            action: 'logout',
        }
        axios.post(this.APIEndPoint + 'users.php', postFields, { withCredentials: true })
            .then(response => {
              setTimeout(() => {
                  router.push('/signin');
              }, 600)
            })
        },
    },
    created(){
      this.APIEndPoint = this.$hostname;
    }
  };
  </script>
  