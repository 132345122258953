import Vue from 'vue'
import VueRouter from 'vue-router'
import Signin from '../views/SigninView.vue'
import appHome from '../views/AppHome.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: appHome },
  { path: '/signin', component: Signin },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});


export default router
