<template v-if="loggedIn">
    <div>
        <loginHeader @open-add-user="openAddNewuser"></loginHeader>
        <div class="app-main">
            <v-container>
                <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card class="stat-card elevation-0 blueCard">
          <v-card-text class="text-center">
            <v-row>
                <v-col cols="3" class="d-flex align-center">
                    <v-icon class="mdi-48px light-text-color">mdi-account-group</v-icon>
                </v-col>
                <v-col>
                    <div class="stat-label light-text-color">Total Users</div>
                    <div class="stat-value light-text-color">1000</div>
                </v-col>
            </v-row>
            
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="stat-card elevation-0 greenCard">
          <v-card-text class="text-center">
            <v-row>
                <v-col cols="3" class="d-flex align-center">
                    <v-icon class="mdi-48px light-text-color">mdi-calendar-month</v-icon>
                </v-col>
                <v-col>
                    <div class="stat-label light-text-color">Users This Month</div>
                    <div class="stat-value light-text-color">200</div>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="stat-card elevation-0 redCard">
          <v-card-text class="text-center">
            <v-row>
                <v-col cols="3" class="d-flex align-center">
                    <v-icon class="mdi-48px light-text-color">mdi-ticket</v-icon>
                </v-col>
                <v-col>
                    <div class="stat-label light-text-color">Tickets</div>
                    <div class="stat-value light-text-color">50</div>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
                <v-row class="mt-5">
                    <v-col cols="12" md="8" class="elevation-1">
                        <h3>User management</h3>
                        <v-text-field
          v-model="userSearch"
          label="Search"
          class="mx-4"
        ></v-text-field>
                        <v-data-table
                            :headers="headers"
                            :items="listUsers"
                            :items-per-page="5"
                            class="elevation-1"
                            :search="userSearch"
                        ></v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-row justify="center">
            <v-dialog v-model="addProfile" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Add profile</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click="addProfile = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-col cols="12" md="4" elevation="1">
                        <v-list three-line subheader>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-col cols="12">
                                        <v-text-field v-model="newUserData.username" label="Username"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="newUserData.email" label="Email"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="newUserData.password" label="Password" type="password"></v-text-field>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-col cols="12">
                                        <v-file-input
                                            label="Avatar"
                                            clearable
                                            dense
                                            flat
                                            outlined
                                            prepend-inner-icon="mdi-camera"
                                            prepend-icon=""
                                            @change="uploadAvatar"
                                        ></v-file-input>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-col cols="12">
                                        <v-file-input
                                            label="Background"
                                            clearable
                                            dense
                                            flat
                                            outlined
                                            prepend-inner-icon="mdi-camera"
                                            prepend-icon=""
                                            @change="uploadBanner"
                                        ></v-file-input>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Bio</v-list-item-title>
                                    <v-col cols="12">
                                        <v-textarea outlined dense label="Bio" v-model="newUserData.bio"></v-textarea>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>General</v-list-item-title>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.gender" :items="genders" item-text="text"
                                            item-value="value" label="Gender"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-menu ref="dob" v-model="dobSelector" :close-on-content-click="false"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="newUserData.dob" label="Birthday date"
                                                    append-icon="mdi-calendar" readonly v-bind="attrs"
                                                    v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="newUserData.dob"
                                                :active-picker.sync="activeDobSelector"
                                                :max="minDate"
                                                min="1950-01-01"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.sexuality" :items="sexualities"
                                            label="Sexuality"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.sexualActivity" :items="sexualActivity"
                                            label="Sexual Activity"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.lookingFor" :items="lookingFor"
                                            label="Looking for"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.country" :items="countries"
                                            label="Country"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.city" :items="cities" label="City"></v-select>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Body features</v-list-item-title>
                                    <v-col cols="12">
                                        <v-text-field v-model="newUserData.height"
                                            label="Height (in  cm)"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="newUserData.weight"
                                            label="Weight (in kg)"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.hair" :items="hairs" label="Hair"></v-select>
                                    </v-col>
                                    <v-col cols="12" v-if="newUserData.gender == 'male'">
                                        <v-select v-model="newUserData.facialHair" :items="facialHairs"
                                            label="Facial hair"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.pubicHair" :items="pubicHairs"
                                            label="Pubic hair"></v-select>
                                    </v-col>
                                    <v-col cols="12" v-if="newUserData.gender == 'male'">
                                        <v-select v-model="newUserData.bodyHair" :items="bodyHairs"
                                            label="Body hair"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.hairColor" :items="hairColors"
                                            label="Hair color"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.tattoo" multiple :items="tattoos"
                                            label="Tattoos"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.piercing" multiple :items="piercings"
                                            label="Piercings"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.eyeColor" :items="eyeColors"
                                            label="Eye color"></v-select>
                                    </v-col>
                                    <v-col cols="12" v-if="newUserData.gender == 'female'">
                                        <v-select v-model="newUserData.breastSize" :items="breastSizes"
                                            label="Breast size"></v-select>
                                    </v-col>
                                    <v-col cols="12" v-if="newUserData.gender == 'male'">
                                        <v-text-field v-model="newUserData.penisSize"
                                            label="Penis size (in cm)"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.abs" :items="abs" label="Abs"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.bottomSizes" :items="bottomSizes"
                                            label="Bottom size"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.bodyTypes" :items="bodyTypes"
                                            label="Body type"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.ethnicities" :items="ethnicities"
                                            label="Ethnicity"></v-select>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Body and lifestyle</v-list-item-title>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.fitnessLevels" :items="fitnessLevels"
                                            label="Fitness level"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.dietaryPreferences" :items="dietaryPreferences" multiple
                                            label="Dietary preferences"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.healthHabits" :items="healthHabits" multiple
                                            label="Health habits"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.exerciseFrequency" :items="exerciseFrequency"
                                            label="Exercise frequency"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.sleepingHabits" :items="sleepingHabits"
                                            label="Sleeping habits"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.foodAllergies" :items="foodAllergies" multiple
                                            label="Food allergies"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.alcoholConsumptions"
                                            :items="alcoholConsumptions" label="Alcohol consumption"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.smoking" :items="smoking"
                                            label="Smoking"></v-select>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Financial</v-list-item-title>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.jobs" :items="jobs" label="Job"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="newUserData.netWorth" label="Net worth (in euros)"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="newUserData.yearlySalary"
                                            label="Yearly salary (in euros)"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.mainLiving" :items="mainLiving"
                                            label="Main living"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.financialGoals" :items="financialGoals"
                                            label="Financial goals"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.spendingHabits" :items="spendingHabits"
                                            label="Spending habits"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.financialIndependence"
                                            :items="financialIndependence" label="Financial Independence"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.inDebt" :items="yesNo"
                                            label="In debt"></v-select>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Personal</v-list-item-title>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.hasKids" :items="yesNo"
                                            label="Has kid(s)"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.wantKids" :items="yesNo"
                                            label="Want kid(s)"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.fetishes" :items="fetishes" multiple
                                            label="Fetishes"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.personalities" :items="personalities" multiple
                                            label="Personality"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.hobbiesAndActivities"
                                            :items="hobbiesAndActivities" multiple
                                            label="Hobbies and activities"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.movieGenres" :items="movieGenres" multiple
                                            label="Movie genres"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.bookGenres" :items="bookGenres" multiple
                                            label="Book genres"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.highestGraduation" :items="highestGraduation"
                                            label="Highest education"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.education" :items="education" multiple
                                            label="Education"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.travelPreferences" :items="travelPreferences"
                                            multiple label="Travel preferences"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.religion" :items="religion"
                                            label="Religion"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.languages" :items="languages" multiple
                                            label="Languages"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.currentSituation" :items="currentSituation"
                                            label="Current situation"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="newUserData.pets" :items="pets" multiple
                                            label="Pets"></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn color="primary" @click="insertUser()">Save</v-btn>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios';
import loginHeader from '@/components/loginHeader.vue'
import router from '@/router';
export default {
    name: 'appHome',
    components: {
        loginHeader,
    },
    data() {
        return {
            addProfile: false,
            APIEndPoint: '',
            imageDomain: '',
            loggedIn: false,
            userSearch: '',
            headers: [
                { text: 'User id', value: 'usid'},
                { text: 'Username', value: 'username'},
                { text: 'Email', value: 'email' },
                { text: 'Signed up', value: 'regDate' },
                { text: 'Stripe customer', value: 'stripe_cu' },
            ],
            listUsers: [],
            avatarPlaceholder: null,
            backgrouncPlaceholder: null,
            newUserData: {
                username: null,
                password:  null,
                email: null,
                bio: null,
                avatar: null,
                banner: null,
                gender: null,
                dob: null,
                sexuality: null,
                sexualActivity: null,
                lookingFor: null,
                country: null,
                city: null,
                height: null,
                weight: null,
                hair: null,
                facialHair: null,
                pubicHair: null,
                bodyHair: null,
                hairColor: null,
                tattoo: [],
                piercing: [],
                eyeColor: null,
                breastSize: null,
                penisSize: null,
                abs: null,
                bottomSizes: null,
                bodyTypes: null,
                ethnicities: null,
                fitnessLevels: null,
                dietaryPreferences: [],
                healthHabits: [],
                exerciseFrequency: null,
                sleepingHabits: null,
                foodAllergies: [],
                alcoholConsumptions: null,
                smoking: null,
                jobs: null,
                netWorth: null,
                yearlySalary: null,
                mainLiving: null,
                financialGoals: null,
                spendingHabits: null,
                financialIndependence: null,
                inDebt: null,
                hasKids: null,
                wantKids: null,
                fetishes: [],
                personalities: [],
                hobbiesAndActivities: [],
                movieGenres: [],
                bookGenres: [],
                highestGraduation: null,
                education: null,
                travelPreferences: [],
                religion: null,
                languages: [],
                currentSituation: null,
                pets: [],
            },
            sexualActivity: [],
            sexualities: [],
            lookingFor: [],
            hairs: [],
            hairColors: [],
            facialHairs: [],
            pubicHairs: [],
            bodyHairs: [],
            tattoos: [],
            piercings: [],
            eyeColors: [],
            breastSizes: [],
            abs: [],
            bottomSizes: [],
            bodyTypes: [],
            ethnicities: [],
            fitnessLevels: [],
            dietaryPreferences: [],
            healthHabits: [],
            exerciseFrequency: [],
            sleepingHabits: [],
            foodAllergies: [],
            alcoholConsumptions: [],
            smoking: [],
            jobs: [],
            mainLiving: [],
            financialGoals: [],
            spendingHabits: [],
            financialIndependence: [],
            fetishes: [],
            personalities: [],
            hobbiesAndActivities: [],
            movieGenres: [],
            bookGenres: [],
            highestGraduation: [],
            education: [],
            travelPreferences: [],
            communicationPreferences: [],
            religion: [],
            languages: [],
            currentSituation: [],
            pets: [],
            countries: [],
            cities: [],
            searching: false,
            countryList: null,
            genders: [
                { text: 'Male', value: 'male' },
                { text: 'Female', value: 'female' },
                { text: 'Other', value: 'other' },
            ],
            yesNo: [
                { text: 'Yes', value: 'yes' },
                { text: 'No', value: 'no' }
            ],
            dobSelector: false,
            activeDobSelector: null,
        }
    },
    methods: {
        isAuth() {
            var postFields = {action: 'isAuth'}
            axios.post(this.APIEndPoint + 'users.php', postFields, { withCredentials: true }).then(response => {
                if (response.data.status === false) {
                    console.log('Not loggedin')
                    router.push('/signin');
                } else {
                    this.loggedIn = true;
                }
            })
        },
        getUsers() {
            this.listUsers = [];
            var postFields = {action: 'getUsers'}
            axios.post(this.APIEndPoint + 'users.php', postFields, { withCredentials: true }).then(response => {
                if(response.data && response.data.length){
                    let users = JSON.stringify(response.data);
                    users = JSON.parse(users);
                    this.listUsers = users;
                }else{
                    this.listUsers = [];
                }
            })
        },
        uploadAvatar(event) {
            this.newUserData.avatar = event;
        },
        uploadBanner(event) {
            this.newUserData.banner = event;
        },
        async insertUser() {
            try {
                const postData = new FormData();
                const userDataJson = JSON.stringify(this.newUserData);
                postData.append('userData', userDataJson);
                postData.append('avatar', this.newUserData.avatar);
                postData.append('banner', this.newUserData.banner);
                postData.append('action', 'insertUser');
                const response = await axios.post(this.APIEndPoint + 'users.php', postData, {withCredentials: true});
                this.setUserData();
                this.newUserData.banner = null;
                this.newUserData.avatar = null;
            } catch (error) {
                console.log('Error');
            }
        },
        initUserData(){
            let newUserData = {
                username: null,
                password:  null,
                email: null,
                bio: null,
                avatar: null,
                banner: null,
                gender: null,
                dob: null,
                sexuality: null,
                sexualActivity: null,
                lookingFor: null,
                country: null,
                city: null,
                height: null,
                weight: null,
                hair: null,
                facialHair: null,
                pubicHair: null,
                bodyHair: null,
                hairColor: null,
                tattoo: [],
                piercing: [],
                eyeColor: null,
                breastSize: null,
                penisSize: null,
                abs: null,
                bottomSizes: null,
                bodyTypes: null,
                ethnicities: null,
                fitnessLevels: null,
                dietaryPreferences: [],
                healthHabits: [],
                exerciseFrequency: null,
                sleepingHabits: null,
                foodAllergies: [],
                alcoholConsumptions: null,
                smoking: null,
                jobs: null,
                netWorth: null,
                yearlySalary: null,
                mainLiving: null,
                financialGoals: null,
                spendingHabits: null,
                financialIndependence: null,
                inDebt: null,
                hasKids: null,
                wantKids: null,
                fetishes: [],
                personalities: [],
                hobbiesAndActivities: [],
                movieGenres: [],
                bookGenres: [],
                highestGraduation: null,
                education: null,
                travelPreferences: [],
                religion: null,
                languages: [],
                currentSituation: null,
                pets: [],
            }
            this.newUserData = newUserData;
        },
        setUserData(){
            let newUserData = {
                username: null,
                password:  null,
                email: null,
                bio: null,
                avatar: null,
                banner: null,
            }
            this.newUserData = newUserData;
        },
        async loadCountriesAndCities() {
            try {
                const response = await axios.get(this.APIEndPoint + 'settings.php?countries=true');
                this.countryList = response.data;
                const countryNames = Object.keys(response.data);
                this.countries = countryNames;
            } catch (error) {
                // console.error('Error loading countries and cities:');
            }
        },
        async loadFilterItems() {
            try {
                const response = await axios.get(this.APIEndPoint + 'settings.php?allowedFilter=true');
                var filter = response.data;
                this.sexualities = filter.sexualities;
                this.sexualActivity = filter.sexualActivity;
                this.lookingFor = filter.lookingFor;
                this.hairs = filter.hairs;
                this.hairColors = filter.hairColors;
                this.facialHairs = filter.facialHairs;
                this.pubicHairs = filter.pubicHairs;
                this.bodyHairs = filter.bodyHairs;
                this.tattoos = filter.tattoos;
                this.piercings = filter.piercings;
                this.eyeColors = filter.eyeColors;
                this.breastSizes = filter.breastSizes;
                this.abs = filter.abs;
                this.bottomSizes = filter.bottomSizes;
                this.bodyTypes = filter.bodyTypes;
                this.ethnicities = filter.ethnicities;
                this.fitnessLevels = filter.fitnessLevels;
                this.dietaryPreferences = filter.dietaryPreferences;
                this.healthHabits = filter.healthHabits;
                this.exerciseFrequency = filter.exerciseFrequency;
                this.sleepingHabits = filter.sleepingHabits;
                this.foodAllergies = filter.foodAllergies;
                this.alcoholConsumptions = filter.alcoholConsumptions;
                this.smoking = filter.smoking;
                this.jobs = filter.jobs;
                this.mainLiving = filter.mainLiving;
                this.financialGoals = filter.financialGoals;
                this.spendingHabits = filter.spendingHabits;
                this.financialIndependence = filter.financialIndependence;
                this.fetishes = filter.fetishes;
                this.personalities = filter.personalities;
                this.hobbiesAndActivities = filter.hobbiesAndActivities;
                this.movieGenres = filter.movieGenres;
                this.bookGenres = filter.bookGenres;
                this.highestGraduation = filter.highestGraduation;
                this.education = filter.education;
                this.travelPreferences = filter.travelPreferences;
                this.communicationPreferences = filter.communicationPreferences;
                this.religion = filter.religion;
                this.languages = filter.languages;
                this.currentSituation = filter.currentSituation;
                this.pets = filter.pets;
            } catch (error) {
                console.log('Error');
            }
        },
        openAddNewuser(){
            this.addProfile = true;
        },
    },
    computed: {
        minDate() {
            const today = new Date();
            const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
            return minDate.toISOString().substring(0, 10);
        },
    },
    mounted() {
        this.isAuth();
        this.getUsers();
        this.loadFilterItems();
        this.loadCountriesAndCities();
    },
    created() {
        this.APIEndPoint = this.$hostname;
        this.imageDomain = this.$imageDomain;
    },
    watch: {
        'newUserData.country': function (newCountry) {
            if (this.countryList[newCountry]) {
                this.cities = this.countryList[newCountry];
            } else {
                this.cities = [];
            }
        },
    },
}
</script>

<style scoped>
.stat-card {
  border-radius: 10px;
  padding: 20px;
  color:#999 !important;
}

.stat-label {
  font-size: 18px;
  margin-top: 10px;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  margin-top: 5px;
}

.blueCard{
    background: #8E2DE2;
        background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2); 
        background: linear-gradient(to right, #4A00E0, #8E2DE2);

}
.greenCard{
    background: #348F50;
    background: -webkit-linear-gradient(to left, #56B4D3, #348F50); 
    background: linear-gradient(to left, #56B4D3, #348F50);
}
.redCard{
    background: #c31432;
    background: -webkit-linear-gradient(to left, #240b36, #c31432);
    background: linear-gradient(to left, #240b36, #c31432);

}
.light-text-color{
    color:#fff;
}
</style>
